<template>
  <HelloWorld :gameName="gameName" :imgLink="imgLink" :AppName="AppName" />
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  data() {
    return {
      gameName: "BK8AUS",
      AppName: "bk8aus",
      imgLink: "https://bk8aus.apk96.site/IMG/logo.png",
    };
  },
  mounted() {
    this.setPageTitle(this.gameName);
    this.setFavicon(this.imgLink);
  },
  methods: {
    setPageTitle(title) {
      document.title = title;
    },
    setFavicon(faviconPath) {
      const link = document.createElement('link');
      link.rel = 'icon';
      link.type = 'image/x-icon';
      link.href = faviconPath;
      document.head.appendChild(link);
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
